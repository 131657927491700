<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="queryForm" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan" v-if="!currentUserInfo.department || currentUserInfo.department === 5">
          <a-form-item label="部门">
            <a-select showSearch allowClear style="width: 100%" v-model="queryForm.department">
              <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="客户名称">
            <a-input v-model="queryForm.customer_name" />
          </a-form-model-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="状态">
            <a-select allowClear style="width: 100%" v-model="queryForm.status">
              <a-select-option v-for="op in remindStatusOps" :key="op.value">{{ op.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="margin:15px 0;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="statusSolt" slot-scope="text">
        <a-tag :color="text === 'payed'?'green':'red'">{{text==='payed'?'已付款':'待付款'}}</a-tag>
      </div>
      <!-- <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">修改</a>
        <a @click="delRecord(record)">删除</a>
      </div> -->
    </a-table>
  </a-card>
</template>
<script>
import {
  getCustomer,
  queryCustomerRemindList
} from '@/api/common'
import debounce from 'lodash/debounce'
export default {
  data() {
    this.lastFetchId = 0
    this.getCustomerInfo = debounce(this.getCustomerInfo, 400)
    return {
      labelCol: { span: 5 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      currentUserInfo: this.$store.getters.userInfo,
      remindStatusOps: [
        { value: 'wait_pay', name: '待付款' },
        { value: 'payed', name: '已付款' }
      ],
      queryForm: {
        customer_name: '',
        department: null,
        status: null
      },
      formModel: {
        id: null,
        department: this.$store.getters.userInfo.department,
        customer_id: null,
        charge_deadline_day: null
      },
      rules: {
        charge_deadline_day: [{ required: true, message: '请填写开票后付款天数', trigger: 'blur' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: (text) => {
            if (text === 0) return '-'
            if (text === 100) return '混合部门'
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name',
          align: 'center'
        },
        {
          title: '开票日期',
          dataIndex: 'open_time',
          align: 'center'
        },
        {
          title: '设定逾期天数',
          dataIndex: 'charge_deadline_day',
          align: 'center'
        },
        {
          title: '实际天数',
          dataIndex: 'days',
          align: 'center',
          customRender: (text, record) => {
            if (record.status === 'wait_pay') return ''
            return text
          }
        },
        {
          title: '资金来款日期',
          dataIndex: 'arrive_date',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'statusSolt' }
        },
        {
          title: '下次发送邮件',
          dataIndex: 'next_remind_time',
          align: 'center'
        },
        {
          title: '发送次数',
          dataIndex: 'remind_times',
          align: 'center'
        },
        {
          title: '发送结果',
          dataIndex: 'email_result',
          align: 'center'
        },
        {
          title: '结算单',
          dataIndex: 'balance_serial_num',
          align: 'center'
        },
        {
          title: '业务编号',
          dataIndex: 'order_nums',
          align: 'center'
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增客户开票后付款天数',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'customer_info',
      customerInfo: undefined,
      customerId: null,
      customerFetching: false,
      customerOps: []
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    getCustomerInfo(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.payerCustomerOps = []
      this.payerFetching = true
      getCustomer({
        department: this.$store.getters.userInfo.department,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.customerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
          this.customerId = res[0].value
          this.formModel.customer_id = res[0].value
        } else {
          this.customerInfo = undefined
          this.customerId = null
          this.formModel.customer_id = null
        }
        this.customerOps = res
        this.customerFetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.customerId = null;
        this.formModel.customer_id = null
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.customerId = value.key;
        this.formModel.customer_id = value.key;
      }
      this.customerOps = []
      this.customerFetching = false
    },
    getInfo(param) {
      queryCustomerRemindList({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.records
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      if (this.currentUserInfo.department && this.currentUserInfo.department !== 5) {
        this.queryForm.department = this.currentUserInfo.department
      }
      console.info(this.queryForm)
      this.getInfo(Object.assign({}, this.queryForm))
    },
    changeTable(pagination) {
      if (this.currentUserInfo.department && this.currentUserInfo.department !== 5) {
          this.queryForm.department = this.currentUserInfo.department
        }
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.queryForm))
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
